const root = document.documentElement;

const changeTimeout = 200;

let lastPercent = 0;
let ignoreChange = true;
let lastChange = 0;

function updateScroll(timestamp) {
    if (lastChange === timestamp || (timestamp - lastChange > changeTimeout && !ignoreChange)) {
        return;
    }
    ignoreChange = false;

    const rawPercent = window.scrollY / window.innerHeight;

    if (rawPercent > 1) {
        return;
    }

    window.requestAnimationFrame(updateScroll)

    const percent = Math.min(rawPercent, 1);
    if (lastPercent === percent) {
        return;
    }
    lastPercent = percent;
    lastChange = timestamp;
    root.style.setProperty('--logo-scale', (Math.max(0.10, 1 - percent)).toFixed(5));
}

function requestScroll() {
    const rawPercent = window.scrollY / window.innerHeight;

    if (rawPercent > 1) {
        return;
    }

    ignoreChange = true;
    window.requestAnimationFrame(updateScroll);
}

window.addEventListener('scroll', requestScroll);
